<template>
  <v-app>
    <div>
      <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Add Requests</h1>
        </div>
        <v-form ref="salarycomponent" v-model="valid">
          <v-row no-gutters>
            <v-col class="mb-6" >
              <!-- <TextField v-model="formData.personnel" :label="'Personnel'" /> -->
              <v-btn class="btn-style justify-start text-style" @click.prevent="openPersonnelSearchModal"  width="100%" height="38" outlined >  <v-icon left>mdi-magnify</v-icon> Personnel</v-btn>
            </v-col>
          <!-- selected personnel list -->
            <v-col v-if="personnel.length > 0" class="mb-6">
              <v-data-table
                dense
                :headers="personnelHeaders"
                :items="personnel"

                class="elevation-1 row-pointer"
                :hide-default-header="true"
                :hide-default-footer="true"
              >

                  <template v-slot:item.actions="{ item }">
                  <div class="actionBtn">
                    <v-icon
                    small
                    @click="removePersonnel(item)"
                  >
                    mdi-delete
                  </v-icon>
                  </div>
                </template>

              </v-data-table>
            </v-col>
            <v-col >
              <SelectField
                :items="requestType"
                v-model="formData.request"
                :label="'Request Type'"
                :itemText="'name'"
                :itemValue ="'id'"
                @onChange ='requestTypeSelected'
                :returnObject="true"

              />
            </v-col>
            <v-col v-if="isLeave" >
              <SelectField
                :items="leaveType"
                v-model="formData.leaveId"
                :label="'Leave Type'"
                :itemText="'name'"
                :itemValue ="'id'"

              />
            </v-col>
            <v-col v-else-if="isAllowance" >
              <SelectField
                :items="salaryComponent"
                v-model="formData.salaryComponentId"
                :label="'Allowance'"
                :itemText="'description'"
                :itemValue ="'id'"

              />
            </v-col>
            <v-col >
              <SelectField
                :items="salaryCycles"
                v-model="formData.salaryCycle"
                :label="'Salary Cycle'"
                :itemText="'description'"
                :itemValue ="'id'"
                @onChange="salaryCycleSelected"
                :returnObject="true"

              />
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>

  <!-- Dialog box -->
  <Dialog ref="personnelSearch" :title="'Personnel Search'" :width="600">
    <v-row>
      <v-col cols="12">
         <v-text-field
            v-model="search"
            outlined
            label="Search"
            @blur="personnelSearchFunction"
            dense
          />
      </v-col>
      <v-col cols="12" v-if="personnelSearch.length > 0">
        <v-data-table
          dense
          @click:row="selectPersonnel"
          :headers="searchHeaders"
          :items="personnelSearch"         
          class="elevation-1 row-pointer"
          :hide-default-footer="true"
        ></v-data-table>
      </v-col>
      </v-row>
    <template v-slot:footer>
      <Button :label="'Close'" :btnType="'Cancel'" @onClick="closePersonnelSearchModal" />
    </template>

  </Dialog>
  <!--End Dialog box -->

  <!-- request calendar  -->

      <div v-if="personnel.length > 0 && valid " class="scroll row">
         <div class="col-md-10 col-sm-12 ml-auto mr-auto">
           <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Personnel</th>
            <th v-for="item in headers" :key="item.date">{{item.date}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="pr in personnel" :key="pr.name">
            <td>{{pr.name}}</td>
            <td v-for="item in headers" :key="item.date">
              <input
                type="checkbox"
                class="checkbox"
                :value="{date:item.value,EmployeeId:pr.id }"
                v-model="selected"
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
         </div>

    </div>

      <!--end request calendar  -->
    <v-row>
      <v-col cols="9 mt-5" class="d-flex justify-end">

          <Button
          :label="'Submit'"
          :btnType="'Submit'"
          @onClick="onSave"
          :disabled="!valid"
          :isLoading="isSavingUser"
          class="mr-4"
        />

        <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />

      </v-col>
    </v-row>
  </v-app>
</template>
<script>
// import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import { CREATE_REQUEST } from '@/store/action-type'
import { salaryComponentService, salaryPeriodService, employeeService, requestService } from '@/services'
import moment from 'moment'
export default {
  components: {
    // TextField,
    SelectField,
    Dialog,
    Button
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      isSavingUser: false,
      formData: {
        request: {},
        personnel: '',
        salaryCycle: {},
        salaryComponentId: '',
        ResumptionDate: '',
        LeaveDays: '',
        isClaim: false,
        leaveId: ''
      },
      isLeave: false,
      isAllowance: false,
      search: '',
      personnelSearch: [],
      requestType: [

      ],
      salaryCycles: [],
      personnel: [],
      selected: [],
      salaryComponent: [],
      leaveType: [],
      headers: [],
      searchHeaders: [
        {
          text: 'FullName',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'email',
          value: 'email'
        }
      ],
      personnelHeaders: [
        {
          glutenfree: true,
          text: 'Name',
          value: 'name'
        },
        { text: 'Actions', value: 'actions' }
      ]
    }
  },

  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    onSave () {
      // this.isSavingUser = true
      let data = []
      this.selected.forEach((per) => {
        const ResumptionDate = moment(per.date).add(1, 'days').format('YYYY-MM-DD')
        return (data.push({ EmployeeId: per.EmployeeId, salaryComponentId: this.formData.salaryComponentId, requestTypeId: this.formData.request.id, salaryPeriodId: this.formData.salaryCycle.id, StartDate: per.date, LeaveDays: this.formData.LeaveDays, isClaim: this.formData.isClaim, ResumptionDate: ResumptionDate, leaveId: this.formData.leaveType }))
      })

      console.log('data', data)
      this.$store.dispatch(CREATE_REQUEST, data).then(() => {
        this.showAlertMessage('Request creation successful', 'success')
      }).catch((error) => {
        console.log(error)
        this.showAlertMessage('Request creation failed', 'error')
      }).finally(() => {
        this.isSavingUser = false
        this.onReset()
      })
    },
    salaryCycleSelected () {
      this.headers = this.dateArray(this.formData.salaryCycle.startDate, this.formData.salaryCycle.endDate)

      console.log(this.formData.salaryCycle)
    },
    selectPersonnel (item) {
      let index = null
      for (let i = 0; i < this.personnel.length; i++) {
        if (this.personnel[i].id === item.id) {
          index = i
          break
        }
      }
      if (index === 0) {
        return
      }
      console.log(index)
      this.personnel.push({ name: `${item.name}`, id: item.id })
      this.search = ''
    },
    requestTypeSelected (item) {
      console.log(item)
      this.isLeave = this.formData.request.isLeave
      this.isAllowance = this.formData.request.isAllowance
      if (this.isLeave === false) {
        this.formData.leaveId = ''
        this.formData.LeaveDays = 0
      } else {
        this.formData.LeaveDays = 1
      }
      if (this.isAllowance === false) {
        this.formData.salaryComponentId = ''
      }
    },
    openPersonnelSearchModal () {
      this.$refs.personnelSearch.openModal()
    },
    removePersonnel (item) {
      let index = null
      for (let i = 0; i < this.personnel.length; i++) {
        if (this.personnel[i].id === item.id) {
          index = i
          break
        }
      }
      console.log(index)
      this.personnel.splice(index, 1)
    },
    closePersonnelSearchModal () {
      this.personnelSearch = []
      this.$refs.personnelSearch.closeModal()
    },
    onReset () {
      this.$refs.salarycomponent.reset()
      this.personnel = []
      this.formData.request = {}
      this.formData.personnel = ''
      this.formData.salaryCycle = {}
      this.formData.salaryComponentId = ''
      this.formData.ResumptionDate = ''
      this.formData.LeaveDays = ''
      this.formData.isClaim = false
      this.formData.leaveId = ''
    },
    personnelSearchFunction () {
      if (this.search === '') {
        this.personnelSearch = []
        return
      }

      employeeService.searchPersonnel(this.search).then(result => {
        console.log(result)
        this.personnelSearch = result.data
      })
    },

    dateArray (startDate, endDate) {
      let dateArr = []

      let prevDate = moment(startDate)._d

      let nextDate = moment(endDate)._d

      prevDate = moment(prevDate).format('MM-DD-YYYY')
      nextDate = moment(nextDate).format('MM-DD-YYYY')

      var start = new Date(prevDate)
      var end = new Date(nextDate)

      while (start <= end) {
        dateArr.push({ date: moment(start).format('MMM Do'), value: moment(start).format('YYYY-MM-DD') })
        var newDate = start.setDate(start.getDate() + 1)
        start = new Date(newDate)
      }
      return dateArr
    }
  },
  mounted () {
    salaryComponentService.getAllSalaryAllowance().then(result => {
      this.salaryComponent = result.data
    }).catch(error => {
      console.log(error)
    })

    requestService.getLeaveTypes().then(result => {
      this.leaveType = result.data
    }).catch(error => {
      console.log(error)
    })

    requestService.getRequestType().then(result => {
      this.requestType = result.data
    }).catch(error => {
      console.log(error)
    })

    salaryPeriodService.getAllSalaryPeriod().then(result => {
      this.salaryCycles = result.data
    }).catch(error => {
      console.log(error)
    })
  }
}
</script>
<style scoped>

.radiogroup{
  width: 50%;
  align-items: flex-start !important;
}
.row .col{
  box-shadow: none !important;
  padding: 0 !important;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.btn-style{
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
}
.text-style{
  text-transform: capitalize;
}
.actionBtn{
  display: flex;
  justify-content: flex-end;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
